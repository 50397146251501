import {createRouter, createWebHashHistory} from 'vue-router';

import {useAuthStore} from "@/stores/auth.store";
import {EPermissaoSistema} from "@/stores/enums/EPermissaoSistema";
import AppLayout from "@/layout/AppLayout.vue";
import {useHomeStore} from "@/stores/home.store";



const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: "/signin",
            name: "signin",
            meta: {authRequired: false},
            component: () => import('@/views/pages/signin/PageSignin.vue')
        },
        {
            path: "/usuario/recuperacao-senha/:solicitacaoId",
            meta: {authRequired: false},
            component: () => import('@/views/pages/signin/PageRecuperacaoSenha.vue')
        },
        {
            path: '/',
            component: AppLayout,
            children: [
                {
                    path: '/',
                    name: 'home',
                    meta: {authRequired: true},
                    component: () => import('@/views/pages/home/PageHome.vue')
                },
                {
                    path: '/perfil',
                    name: 'perfil',
                    meta: {authRequired: true},
                    component: () => import('@/views/pages/perfil/PagePerfil.vue')
                },
                {
                    path: '/chamados',
                    name: 'chamados',
                    meta: {permissao: EPermissaoSistema.CHAMADO, authRequired: true},
                    component: () => import('@/views/pages/chamado/PageChamados.vue')

                },
                {
                    path: '/empresas',
                    name: 'empresas',
                    meta: {authRequired: true},
                    component: () => import('@/views/pages/empresa/PageEmpresas.vue')

                },
                {
                    path: '/avisos',
                    name: 'avisos',
                    meta: {authRequired: true},
                    component: () => import('@/views/pages/aviso/PageAvisos.vue')

                },

                {
                    path: '/empresa/newsletter',
                    name: 'empresa_newsletter',
                    meta: {authRequired: true},
                    component: () => import('@/views/pages/empresa/PageNewsletter.vue')
                },
                {
                    path: '/usuarios',
                    name: 'usuarios',
                    meta: {permissao:EPermissaoSistema.USUARIO_GERENCIAR, authRequired: true},
                    component: () => import('@/views/pages/usuario/PageUsuarios.vue')
                },

                {
                    path: '/contratos',
                    name: 'contratos',
                    meta: {permissao: EPermissaoSistema.CONTRATO, authRequired: true},
                    component: () => import('@/views/pages/contrato/PageContratos.vue')

                },

                {
                    path: '/assuntos',
                    name: 'assuntos',
                    meta: {permissao: EPermissaoSistema.ASSUNTO, authRequired: true},
                    component: () => import('@/views/pages/assunto/PageAssuntos.vue')
                },

                {
                    path: '/permissao_negada',
                    name: 'permissao_negada',
                    component: () => import("@/layout/layoutPages/PagePermissaoNegada.vue")

                },


            ]
        },


    ]
});

router.beforeEach(async (to, from, next) => {

    const homeStore = useHomeStore();
    homeStore.isShowPaceLoading = true;

    const authStore = useAuthStore();
    const authRequired = (!to.meta ? true : to.meta.authRequired);
    const loggedIn = authStore.isLoggedIn();

    //Verifica se é necessário autenticação para acessar a rota/pagina
    //Verifica se o usuário está logado
    if (authRequired && !loggedIn) {


        //Caso necessita de autenticacao e o perfil nao estiver logado, redireciona para pagina de signin

        next('/signin');

    } else {
        //Está logado

        //Verifica se a rota necessita estar autenticado
        if (authRequired) {

            //Verifica as permissoes
            if (await authStore.verificaPermissoesStore()) {
                //Permissoes verificadas

                //Verifica se a rota necessita de permissao
                if (to.meta.permissao) {

                    //Verifica se o perfil possui a permissão para acessar a rota solicitada
                    if (authStore.temPermissao(to.meta.permissao as EPermissaoSistema)) {
                        //Usuario tem permissao, libera a rota
                        next();
                    } else {
                        //Usuario nao tem permissao - redireciona para pagina especifica
                        next('/permissao_negada');
                    }
                } else {
                    //Rota nao necessita de permissao
                    next(); //continua para rota solicitada
                }


            } else {
                //Deu erro na verificação de permissao, redireciona para o signin
                next('/signin');
            }
        } else {
            //Continua para rota solicitada
            next();
        }

    }
});

router.afterEach(() => {

    const homeStore = useHomeStore();
    homeStore.isShowPaceLoading = false;
})


export default router;
