import type {CategoriaChamadoVm} from "@/type/chamado.type";

export interface UsuarioInternoVm {
    publicId: string;
    nomeCompleto: string;
    apelido: string;
    cpf: string;
    celular: string;
    imagem: string;
    email: string;
    permissoesCount: number;
    categoriasCount: number;
    status: number;
    statusDescricao: string;
    categorias: CategoriaChamadoVm[];
    permissoes: PermissaoSistemaVm[];

}

export enum EStatusUsuario {
    Cadastrado = 1,
    Confirmado = 2,
    Desativado = 3
}

export interface PermissaoSistemaVm {
    codigo: number,
    descricao: string
}



export function dataAsUsuarioInternoVm (data: any) {
    return data as UsuarioInternoVm
}